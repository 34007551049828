import React from 'react';
import './AllTimeChampions.css';

import Champion from './Champion';

import { LeaderBoard, LeaderBoardEntry } from '../types';

interface Props {
  leaderBoard: LeaderBoard | null
}

const AllTimeChampions = ({ leaderBoard } : Props) =>  {
  const entries: LeaderBoardEntry[] = leaderBoard ? leaderBoard.overall : [];

  return (
    <div className="AllTimeChampions-container">
      <h2 className="AllTimeChampions-title">ALL TIME<br/>CHAMPIONS</h2>
      <div className="AllTimeChampions-content">
        <Champion
          entry={entries.length > 1 ? entries[1] : null}
        />
        <Champion
          entry={entries.length > 0 ? entries[0] : null}
          numberOne
        />
        <Champion
          entry={entries.length > 2 ? entries[2] : null}
        />
      </div>
      <div className="AllTimeChampions-justify">{' '}</div>
    </div>
  );
}

export default AllTimeChampions;
