import React, { useEffect, useState } from 'react';
import './App.css';

import axios from 'axios';

import AllTimeChampions from './components/AllTimeChampions';
import Scoreboard from './components/Scoreboard';
import Logo from './components/Logo';

import { LeaderBoard } from './types';

import { mockData } from './mockData';

function App() {
  const [leaderBoard, setLeaderBoard] = useState<LeaderBoard | null>(null);

  const determineLocationId = (): string | null => {
    const queryParamStartIndex = window.location.href.indexOf('locationId=');

    if (queryParamStartIndex < 0) {
      return null;
    }

    const startIndex = queryParamStartIndex + 'locationId='.length;
    const start = window.location.href.substring(startIndex);
    const endIndex = start.indexOf('?');
    
    return endIndex < 0 ? start : start.substring(0, endIndex);
  }

  const getApiRoot = () => {
    const {hostname} = window.location;

    // production
    if (hostname === 'leader-board.tigapo.com') {
      return 'https://prod.tigapo.com/leader-board-service/leaderBoard';
    }

    // local
    if (hostname.includes('localhost')) {
      return '/leaderBoard';
    }

    // development
      return 'https://dev.tigapo.com/leader-board-service/leaderBoard';
  };

  const getLeaderBoard = async () => {
    const locationId = determineLocationId();

    if (locationId !== null) {
      const url = getApiRoot();
      const response = await axios.get(url, { params: {locationId} });
      setLeaderBoard(response.data);  
    } else {
      setLeaderBoard(mockData);
    }
  };

  useEffect(() => {
    getLeaderBoard();
    const timerId = setInterval(() => getLeaderBoard(), 60 * 1000);
  
    return () => clearInterval(timerId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <Logo />
      <header className="App-header">
        {leaderBoard && 
          <>
            <AllTimeChampions leaderBoard={leaderBoard} />
            <Scoreboard leaderBoard={leaderBoard} />
          </>
        }
      </header>
    </div>
  );
}

export default App;
