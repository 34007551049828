export const mockData = {
  overall: [
    {
      name: 'Simone Baley',
      tickets: 320765,
      numberOne: true,
      image: '/SimoneBaley.jpg'
    },
    {
      name: 'Vennesa Wilson',
      tickets: 287202,
      numberOne: false,
      image: '/VennesaWilson.jpg'
    },
    {
      name: 'Larry James',
      tickets: 99430,
      numberOne: false,
      image: '/LarryJames.jpg'
    },
  ],
  day: [
    {
      name: 'Chris Kulp',
      tickets: 125,
      image: './usman.jpg'
    },
    {
      name: 'Amanda Angle',
      tickets: 123,
      image: './female.png'
    },
    {
      name: 'Michelle Mcnamara',
      tickets: 112,
      image: './troy.jpg'
    },
    {
      name: 'Jessica Molina',
      tickets: 101,
      image: './female.png'
    },
    {
      name: 'Alessandrea Puma',
      tickets: 98,
      image: './female.png'
    },
    {
      name: 'Chris Potempa',
      tickets: 76,
      image: './timothy.jpg'
    },
    {
      name: 'Chris Parker',
      tickets: 61,
      image: './male.png'
    },
    {
      name: 'Brie Jones',
      tickets: 54,
      image: './sincerely.jpg'
    },
    {
      name: 'Keely Foster',
      tickets: 46,
      image: './female.png'
    }
  ],
  week: [
    {
      name: 'Daman Cheema',
      tickets: 890,
      image: './male.png'
    },
    {
      name: 'Garrison Scott',
      tickets: 812,
      image: './robbie.jpg'
    },
    {
      name: 'Ian Kramer',
      tickets: 789,
      image: './male.png'
    },
    {
      name: 'Daniel Cafruny',
      tickets: 701,
      image: './ransford.jpg'
    },
    {
      name: 'Dave  Zinicola',
      tickets: 675,
      image: './male.png'
    },
    {
      name: 'Ryan Sassower',
      tickets: 660,
      image: './nishanth.jpg'
    },
    {
      name: 'Brett Morris',
      tickets: 629,
      image: './male.png'
    },
    {
      name: 'Nancy  Porter',
      tickets: 540,
      image: './nojan.jpg'
    },
    {
      name: 'Vivek Kardile',
      tickets: 412,
      image: './male.png'
    }
  ],
  month: [
    {
      name: 'Sammy Moulton',
      tickets: 4756,
      image: './luis.jpg'
    },
    {
      name: 'Bryan Gerstner ',
      tickets: 4230,
      image: './male.png'
    },
    {
      name: 'Pamela  Campbell ',
      tickets: 3907,
      image: './juno.jpg'
    },
    {
      name: 'Nancy  Porter',
      tickets: 3877,
      image: './female.png'
    },
    {
      name: 'John Rivera',
      tickets: 3451,
      image: './harishan.jpg'
    },
    {
      name: 'Tara Diehl',
      tickets: 3220,
      image: './art.jpg'
    },
    {
      name: 'Camryn Elder',
      tickets: 3002,
      image: './male.png'
    },
    {
      name: 'George Ramos',
      tickets: 2745,
      image: './craig.jpg'
    },
    {
      name: 'Jessica Dale',
      tickets: 2305,
      image: './female.png'
    }
  ],
  time: '',
};