import React, { useEffect, useState } from 'react';
import './Scoreboard.css';

import ScoreboardHeader from './ScoreboardHeader';
import ScoreboardTable from './ScoreboardTable';

import { LeaderBoard, LeaderBoardEntry } from '../types';

const titleOpts = [ 'TODAY', 'THIS WEEK', 'THIS MONTH'];

interface Props {
  leaderBoard: LeaderBoard | null
}

const Scoreboard = ({ leaderBoard }: Props) =>  {
  const peopleOpts = leaderBoard ? [leaderBoard.day, leaderBoard.week, leaderBoard.month] : [[], [], []];
  const [optIndex, setOptIndex] = useState<number>(0);
  const [people, setPeople] = useState<LeaderBoardEntry[]>(peopleOpts[optIndex]);
  const [title, setTitle] = useState(titleOpts[optIndex]);

  useEffect(() => {
    const handle = setInterval(() => {
      setOptIndex(i => (i + 1) % titleOpts.length);
    }, 10000);

    return () => {
      clearInterval(handle);
    };
  }, []);

  useEffect(() => {
    setPeople(peopleOpts[optIndex]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optIndex]);

  useEffect(() => {
    setTitle(titleOpts[optIndex]);
  }, [optIndex]);

  return (
    <div className="Scoreboard-container">
      <ScoreboardHeader
        title={title}
      />
      <ScoreboardTable
        key={`table-${optIndex}`}
        people={people}
      />
    </div>
  );
}

export default Scoreboard;
