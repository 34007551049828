import React from 'react';
import './Logo.css';

import logo from '../images/logo.png';

const Logo = () =>  {
  return (
    <div className="Logo-container">
      <img src={logo} alt="tigapo" width={200} />
    </div>
  );
}

export default Logo;
