import React from 'react';
import './Champion.css';

import { LeaderBoardEntry } from '../types';

import frame from '../images/round-frame.png';

interface Props {
  entry: LeaderBoardEntry | null
  numberOne?: boolean
}

const DEFAULT_IMAGE = '/male1.png';

const Champion = (props: Props) =>  {
  const { entry, numberOne = false } = props;
  const name = entry ? entry.name : '';
  const image = entry ? entry.image || DEFAULT_IMAGE : DEFAULT_IMAGE;
  const tickets = entry ? entry.tickets.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';

  return (
    <div className="Champion-container">
      {numberOne && <div className="Champion-crown" />}
      <div className="Champion-avatar">
        <img src={image} alt={name} />
        <img src={frame} alt={name} />
        <div className="Champion-name">{name}</div>
        <div className="gradient"></div>
      </div>
      <div className="Champion-tickets">
        <div className="Champion-tickets-value">{tickets}</div>
        <div className="Champion-tickets-button">
          <span className="Champion-tickets-label">Tickets</span>
        </div>
      </div>
    </div>
  );
}

export default Champion;
