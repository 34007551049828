import React, { useEffect, useState } from 'react';
import './ScoreboardTable.css';
import './Animate.css';

import ScoreboardItem from './ScoreboardItem';

interface Props {
  people: {
    name: string
    tickets: number
    image: string
  }[]
}

const ScoreboardTable = (props: Props) =>  {
  const { people } = props;
  const [className, setClassName] = useState<'slideIn' | 'slideOut'>('slideIn');
  const [peopleToDisplay, setPeopleToDisplay] = useState(people);

  useEffect(() => {
    setClassName('slideOut');
    const timeout = setTimeout(() => {
      setPeopleToDisplay(people);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [people]);

  useEffect(() => {
    setClassName('slideIn');
  }, [peopleToDisplay]);

  const entries = Array.from({ length: 9 }, (v, k) => peopleToDisplay.length > k ? peopleToDisplay[k] : null);

  return (
    <div className={className}>
      <div className={`ScoreboardTable-container`}>
        {entries.map((entry, index) => <ScoreboardItem key={`${entry ? entry.name : 'null'}_${index}`} entry={entry} />)}
      </div>
    </div>
  );
}

export default ScoreboardTable;
