import React, { useEffect, useState } from 'react';
import './ScoreboardHeader.css';
import './Animate.css';

interface Props {
  title: string
}

const ScoreboardHeader = (props: Props) =>  {
  const { title } = props;
  const [titleClassName, setTitleClassName] = useState<'fadeIn' | 'fadeOut'>('fadeIn');
  const [titleToDisplay, setTitleToDisplay] = useState(title);

  useEffect(() => {
    setTitleClassName('fadeOut');
    const timeout = setTimeout(() => {
      setTitleToDisplay(title);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [title]);

  useEffect(() => {
    setTitleClassName('fadeIn');
  }, [titleToDisplay]);

  return (
    <div className="ScoreboardHeader-container">
      <div className="ScoreboardHeader-title">
        <h2 className={titleClassName}>{titleToDisplay}</h2>
      </div>
      <div className="ScoreboardHeader-line-wrapper">
        <div className="ScoreboardHeader-line" />
      </div>
    </div>
  );
}

export default ScoreboardHeader;
