import React from 'react';
import './ScoreboardItem.css';

import frame from '../images/round-frame.png';

import { LeaderBoardEntry } from '../types';

interface Props {
  entry: LeaderBoardEntry | null
}

const DEFAULT_IMAGE = '/male1.png';

const ScoreboardItem = (props: Props) =>  {
  const { entry } = props;
  const name = entry ? entry.name : '-';
  const image = entry ? entry.image || DEFAULT_IMAGE : DEFAULT_IMAGE;
  const tickets = entry ? entry.tickets.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';

  return (
    <div className="ScoreboardItem-container">
      <div className="ScoreboardItem-content">
        <div className="ScoreboardItem-avatar">
          <img src={image} alt={name} />
          <img src={frame} alt={name} />
        </div>
        <div className="ScoreboardItem-name">{name}</div>
        <div className="ScoreboardItem-tickets">
          <div className="ScoreboardItem-tickets-value">{tickets.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
          <div className="ScoreboardItem-tickets-button">
            <span className="ScoreboardItem-tickets-label">Tickets</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScoreboardItem;
